@font-face {
  font-family: "iconfont"; /* Project id 1943896 */
  src: url("iconfont.woff2") format("woff2"), url("iconfont.woff") format("woff"),
    url("iconfont.ttf") format("truetype"), url("iconfont.svg") format("svg");
}
.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-xol::before {
  content: "\e6a8";
}

.icon-recovery::before {
  content: "\e671";
}

.icon-approve::before {
  content: "\e706";
}

.icon-provisions::before {
  content: "\e674";
}

.icon-protect::before {
  content: "\e669";
}

.icon-balance::before {
  content: "\e745";
}

.icon-turn-off::before {
  content: "\e9db";
}

.icon-turn-on::before {
  content: "\e9da";
}

.icon-Medical-Bag::before {
  content: "\eaf8";
}

.icon-sum-insured::before {
  content: "\e6fb";
}

.icon-policy-balance::before {
  content: "\e61d";
}
.icon-policy-in-progress::before {
  content: "\e661";
}
.icon-policy-in-uw::before {
  content: "\e726";
}
.icon-copy-link::before {
  content: "\e615";
}
.icon-share::before {
  content: "\e677";
}
.icon-plus::before {
  content: "\e760";
}
.icon-delete::before {
  content: "\e60a";
}
.icon-option-cover::before {
  content: "\e60f";
}
.icon-more-info::before {
  content: "\e7e2";
}
.icon-settlement::before {
  content: "\e6e7";
}
.icon-blacklist-approval::before {
  content: "\e651";
}
.icon-fnol::before {
  content: "\e6df";
}
.icon-policy::before {
  content: "\e60e";
}
.icon-renewal::before {
  content: "\e756";
}
.icon-UW::before {
  content: "\e656";
}
.icon-endorsement::before {
  content: "\e609";
}
.icon-claims::before {
  content: "\e825";
}
.icon-sales::before {
  content: "\e607";
}
.icon-assignee::before {
  content: "\e611";
}
.icon-copy::before {
  content: "\e66c";
}
.icon-management::before {
  content: "\e66a";
}
.icon-description::before {
  content: "\e668";
}
.icon-download::before {
  content: "\e667";
}
.icon-no-picture::before {
  content: "\e663";
}
.icon-xiao::before {
  content: "\e664";
}
.icon-ku::before {
  content: "\e6b0";
}
.icon-no-video::before {
  content: "\e696";
}
.icon-identify::before {
  content: "\e652";
}
.icon-photo::before {
  content: "\e655";
}
.icon-kyc::before {
  content: "\e658";
}
.icon-video::before {
  content: "\ed2d";
}
.icon-checked-tree::before {
  content: "\e6b3";
}
.icon-some-tree::before {
  content: "\e864";
}
.icon-none-tree::before {
  content: "\e653";
}
.icon-close:before {
  content: "\e60b";
}
.icon-back:before {
  content: "\e6d7";
}
.icon-calculate:before {
  content: "\e659";
}
.icon-hover-edit:before {
  content: "\e622";
}
.icon-mobile-filter:before {
  content: "\ed81";
}
.icon-configuration:before {
  content: "\e64d";
}
.icon-product-common:before {
  content: "\e6f7";
}
.icon-addnote:before {
  content: "\e67b";
}
.icon-my-website:before {
  content: "\e60c";
}
.icon-endo:before {
  content: "\e766";
}
.icon-add:before {
  content: "\e6e3";
}
.icon-pdf:before {
  content: "\e69a";
}
.icon-linkedin:before {
  content: "\e84d";
}
.icon-add-person:before {
  content: "\e642";
}
.icon-birthday:before {
  content: "\e771";
}
.icon-email:before {
  content: "\e602";
}
.icon-iphone:before {
  content: "\e637";
}
.icon-motor:before {
  content: "\e785";
}
.icon-gpc:before {
  content: "\ee31";
}
.icon-cmi:before {
  content: "\ea45";
}
.icon-travel:before {
  content: "\e605";
}
.icon-accident:before {
  content: "\e606";
}
.icon-pa:before {
  content: "\ee32";
}
.icon-home:before {
  content: "\e63c";
}
.icon-maid:before {
  content: "\ebff";
}
.icon-health:before {
  content: "\e6ca";
}
.icon-phs:before {
  content: "\ee33";
}
.icon-grpcancer:before {
  content: "\e632";
}
.icon-grpfleet:before {
  content: "\e660";
}
.icon-sme:before {
  content: "\e608";
}
.icon-wlbos:before {
  content: "\ee34";
}
.icon-boss:before {
  content: "\ee35";
}
.icon-grphealth:before {
  content: "\ec00";
}
.icon-ghs:before {
  content: "\ee36";
}
.icon-eb:before {
  content: "\ee37";
}
.icon-efc:before {
  content: "\e654";
}
.icon-gtl:before {
  content: "\ee38";
}
.icon-gpa:before {
  content: "\ee39";
}
.icon-view:before {
  content: "\e600";
}
.icon-wlf:before {
  content: "\e633";
}
.icon-tlf:before {
  content: "\e619";
}
.icon-annuity:before {
  content: "\e640";
}
.icon-endowment:before {
  content: "\ee3a";
}
.icon-reject:before {
  content: "\e64e";
}
.icon-work-on:before {
  content: "\e604";
}
.icon-search:before {
  content: "\e63b";
}
.icon-line:before {
  content: "\e636";
}
.icon-profile:before {
  content: "\e665";
}
.icon-login-account:before {
  content: "\e603";
}
.icon-my-authorities:before {
  content: "\e601";
}
.icon-sms:before {
  content: "\e621";
}
.icon-card:before {
  content: "\e61b";
}
.icon-clock::before {
  content: "\e894";
}
.icon-tc::before {
  content: "\e60d";
}
.icon-surety::before {
  content: "\e657";
}
.icon-fwb::before {
  content: "\ee3b";
}
.icon-liability::before {
  content: "\ee3c";
}
.icon-engineer::before {
  content: "\e66e";
}
.icon-publ::before {
  content: "\e666";
}
.icon-wicun::before {
  content: "\ee3d";
}
.icon-member-add::before {
  content: "\e6f4";
}
.icon-member-edit::before {
  content: "\e6f2";
}
.icon-member-delete::before {
  content: "\e6f1";
}
.icon-filter::before {
  content: "\e6cc";
}
.icon-more::before {
  content: "\e7e1";
}
.icon-life::before {
  content: "\e745";
}
.icon-checked::before {
  content: "\e732";
}
.icon-add-efc::before {
  content: "\e614";
}
.icon-edit-efc::before {
  content: "\e62a";
}
.icon-view-efc::before {
  content: "\e616";
}
.icon-exchange-rate::before {
  content: "\e617";
}
.icon-OD::before {
  content: "\e65b";
}
.icon-TPV::before {
  content: "\e830";
}
.icon-TPD::before {
  content: "\e61f";
}
.icon-TPBI::before {
  content: "\e8e0";
}
.icon-FPBI::before {
  content: "\e623";
}
.icon-ma::before {
  content: "\e68a";
}
.icon-fire::before {
  content: "\ee3e";
}
.icon-iar::before {
  content: "\e61a";
}
.icon-reinsurer::before {
  content: "\e61c";
}
.icon-broker::before {
  content: "\e624";
}
.icon-code::before {
  content: "\e672";
}
.icon-treaty::before {
  content: "\e626";
}
.icon-treaty-type::before {
  content: "\e625";
}
.icon-cart::before {
  content: "\e629";
}
.icon-cart-show::before {
  content: "\e62b";
}
.icon-cart-amount::before {
  content: "\e628";
}
.icon-cart-record::before {
  content: "\e627";
}
.icon-outputs::before {
  content: "\e695";
}
.icon-add-fac::before {
  content: "\ec20";
}
.icon-delete-fac::before {
  content: "\e641";
}
.icon-admin-email::before {
  content: "\e647";
}
.icon-admin-iphone::before {
  content: "\e648";
}
.icon-ocr::before {
  content: "\e64a";
}
.icon-saomiao1::before {
  content: "\e64c";
}
.icon-facin::before {
  content: "\ee3f";
}
.icon-fac_in::before {
  content: "\e716";
}
.icon-marine::before {
  content: "\e718";
}
.icon-full::before {
  content: "\e693";
}
.icon-upload::before {
  content: "\e9d3";
}
.icon-comments::before {
  content: "\e61e";
}
.icon-misc::before {
  content: "\e670";
}
